<template>
    <Dialog header="Create a channel" v-model:visible="display" :modal="true" class='create-channel-modal' :draggable='false' @show='onShown'>
        <div class='channel-name-input-container'>
            <div :class="{'p-float-label': true, 'input-error': v$.channelName.$error }">
                <InputText id='channelNameInput' type="text" v-model='v$.channelName.$model' @input="resetError('channelName')"/>
                <label for='channelNameInput' class='input-label'>Channel name</label>
            </div>
            <template v-for="error of v$.channelName.$errors" :key="error.$uid">
                <span class='error-message'>{{error.$message}}</span>
            </template>
        </div>
        <div class="field-radiobutton" >
            <div>
                <RadioButton inputId="publicChoice" name="channelType" value="public" v-model="channelType" 
                    v-tooltip.bottom="{value:'Public channels are visible to anyone viewing the group but only group members can join or send messages.', escape: false}"/>
                <label for="publicChoice">Public</label>
            </div>
            <div>
                <RadioButton inputId="privateChoice" name="channelType" value="private" v-model="channelType" 
                    v-tooltip.bottom="{value:'Private channels are only visible to group members.', escape: false}"/>
                <label for="privateChoice">Private</label>
            </div>
        </div>
        <div class='channel-desc-textarea-container'>
            <span :class="{'p-float-label': true, 'input-error': v$.channelDescription.$error }">
                <Textarea id='channelDescriptionTextarea' rows="5" cols="30" v-model='v$.channelDescription.$model'/>
                <label for='channelDescriptionTextarea' class='input-label'>Channel description</label>
                <span class='channel-desc-counter' :style="{color: v$.channelDescription.$error ? '#E63E3E': null}">{{ (channelDescription ? channelDescription.length:0)}} / {{channelDescLimit}} </span>
            </span>
        </div>

        <template #footer>
            <Button class='create-channel-button' label='Create Channel' :disabled='disableSubmitButton' :loading='loading' @click='onCreateChannel()'/>
        </template>
    </Dialog>

</template>

<script>
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import Textarea from 'primevue/textarea';
import RadioButton from 'primevue/radiobutton';
import useVuelidate from '@vuelidate/core'
import { helpers, maxLength } from '@vuelidate/validators';

import ChatService from '../../service/ChatService';
import CustomValidationUtils from '../../utilities/CustomValidationUtils';


export default {
    name: 'CreateChannel',
    setup: () => ({ v$: useVuelidate() }),
    emits: ['create-channel-successful'],
    data() {
        return {
            display: false,
            selectedGroup: null,
            channelName: null,
            channelDescription: null,
            loading: false,
            channelDescLimit: 200,
            channelType: "private",
        }
    },
    components: {
        Dialog, InputText, Textarea, RadioButton
    },

    computed: {
        disableSubmitButton() {
            const isNameAndDescEmpty = !this.channelName && !this.channelDescription 
            return this.v$.$errors.length > 0 || isNameAndDescEmpty ? 'disabled' : null;
        }
    },

    methods: {
        resetError(field) {
            this.v$[field].$reset();
        },

        onShown() {
            const dialogElement = document.getElementsByClassName('create-channel-modal')[0];
            dialogElement.parentElement.style.position = 'absolute';
        },

        open(selectedGroup) {
            this.selectedGroup = selectedGroup;
            this.v$.$reset();
            this.display = true;
            this.channelName = '';
            this.channelDescription = '';
        },

        onCreateChannel() {

            this.v$.channelName.$touch();

            if (this.v$.channelName.$errors.length == 0 && this.selectedGroup) {
                this.loading = true;
                ChatService.createGroupChannel(this.selectedGroup.groupId, this.channelName, this.channelDescription, ( this.channelType == "private" ? true : false), false).then((resp) => {

                    this.loading = false;

                    const summaryString = `Created channel: ${this.channelName}`;

                    if (resp.data.status === 'success') {
                        this.display = false;
                        this.$toast.add({ severity: 'success', summary: `${summaryString} successful!`, life: 2500, group: 'center' });
                        this.$emit('create-channel-successful');
                    }
                    else if( resp.data.message == 'Quota Full') {
                        this.$toast.add({ severity: 'info', summary: "Channels are limited to four per group. (Coming soon: For a nominal monthly fee you can add more.)", life: 4500, group: 'center' });
                    }
                    else if( resp.data.message == 'Invalid Name') {
                        this.$toast.add({ severity: 'error', summary: "Invalid channel name.", life: 3500, group: 'center' });
                    
                    } else {
                        console.error(resp.data.message);
                        this.$toast.add({ severity: 'error', summary: "An unexpected error occurred.  Please try again or click on 'Support' under your profile pic.", life: 3500, group: 'center' });
                    }
                })
            }

        }
    },

    validations() {
        return {
            channelName: {
                noSpaces: helpers.withMessage('Should not contain any spaces, only underscores.', CustomValidationUtils.onlySpacesHyphensUnderscoresParentheses),
                maxLength: maxLength(50)
            },

            channelDescription: {
                maxLength: maxLength(this.channelDescLimit)
            }
        }
    }
}
</script>

<style>

.create-channel-modal {
    position: static;
    font-family: "Trebuchet MS", "Verdana";
    width: 400px;
    border-radius: 16px;
}

.create-channel-modal .p-dialog-header {
    padding: 16px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}
.create-channel-modal .p-dialog-header .p-dialog-title {
    font-size: 24px;
    font-weight: bold;
}

.create-channel-modal .p-dialog-content {
    height: 100%;
    overflow-y: hidden;
    border-top: 1px solid #BFBFBF;
    border-bottom: 1px solid #BFBFBF;
    padding: 24px 16px;
}

.create-channel-modal .p-dialog-footer {
    text-align: center;
    padding: 16px;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
}
</style>
<style scoped>

.field-radiobutton {
    margin-bottom: 24px;
    padding: 0px 80px;
    display:flex;
    justify-content: space-around;
}

.field-radiobutton .p-radiobutton {
    margin-right: 4px;
}

/* Shaking animation */
@keyframes shakeError {
  0% {
    transform: translateX(0); }
  15% {
    transform: translateX(0.375rem); }
  30% {
    transform: translateX(-0.375rem); }
  45% {
    transform: translateX(0.375rem); }
  60% {
    transform: translateX(-0.375rem); }
  75% {
    transform: translateX(0.375rem); }
  90% {
    transform: translateX(-0.375rem); }
  100% {
    transform: translateX(0); } }

.channel-name-input-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
}
.channel-name-input-container .p-inputtext {
    font-size: 16px;
    width: 100%;
    padding: 8px 8px 8px 16px;
    height: 36px;
    color: black;
    background: #F2F4FA;
    border: 2px solid #BFBFBF;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
}
.channel-name-input-container .p-inputtext:enabled:hover {
    border-color: #33CC99;
}
.channel-name-input-container .p-inputtext:enabled:focus {
    border-color: #33CC99;
}
.channel-name-input-container > label {
    font-size: 16px;
    padding-left: 16px;
    color: #BFBFBF;
}

.channel-desc-textarea-container .p-inputtextarea {
    font-size: 16px;
    resize: none;
    width: 100%;
    padding: 8px 8px 8px 16px;
    color: black;
    background: #F2F4FA;
    border: 2px solid #BFBFBF;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
}
.channel-desc-textarea-container .p-inputtextarea::-webkit-scrollbar {
	/* width */
	width: 8px;
}
.channel-desc-textarea-container .p-inputtextarea::-webkit-scrollbar-thumb {
	background-color: #BFBFBF;    /* color of the scroll thumb */
	border-radius: 20px;       /* roundness of the scroll thumb */
	border: 9px solid #BFBFBF;  /* creates padding around scroll thumb */
}
.channel-desc-textarea-container .p-inputtext:enabled:hover {
    border-color: #33CC99;
}
.channel-desc-textarea-container .p-inputtext:enabled:focus {
    border-color: #33CC99;
}
.channel-desc-textarea-container > label {
    font-size: 16px;
    padding-left: 16px;
    color: #BFBFBF;
}
.channel-desc-textarea-container .channel-desc-counter {
    font-size: 12px;
}

.input-error {
    animation-name: shakeError;
    animation-fill-mode: forwards;
    animation-duration: .6s;
    animation-timing-function: ease-in-out;
}
.input-error .p-inputtext:hover,
.input-error .p-inputtext:enabled:focus,
.input-error .p-inputtext {
    border-color: #E63E3E;
}
.input-error .input-label {
    color: #E63E3E;
}
.error-message:nth-child(2) {
    padding-top: 10px;
}
.error-message {
    color: #E63E3E;
    padding-left: 10px;
}

.create-channel-button {
    padding: 8px 32px;
    background: #33CC99;
    border: 1px solid #33CC99;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
}
.create-channel-button:hover {
    background: #00C083;
    border-color: #00C083;
}
.create-channel-button:focus {
    box-shadow: none;
    border-color: #33CC99;
}

</style>